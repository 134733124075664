import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const CashEvent = () => import('@/entities/cash-event/cash-event.vue');
// prettier-ignore
const CashEventUpdate = () => import('@/entities/cash-event/cash-event-update.vue');
// prettier-ignore
const CashEventDetails = () => import('@/entities/cash-event/cash-event-details.vue');
// prettier-ignore
const Tva = () => import('@/entities/tva/tva.vue');
// prettier-ignore
const TvaUpdate = () => import('@/entities/tva/tva-update.vue');
// prettier-ignore
const TvaDetails = () => import('@/entities/tva/tva-details.vue');
// prettier-ignore
const Terminal = () => import('@/entities/terminal/terminal.vue');
// prettier-ignore
const TerminalUpdate = () => import('@/entities/terminal/terminal-update.vue');
// prettier-ignore
const TerminalDetails = () => import('@/entities/terminal/terminal-details.vue');
// prettier-ignore
const Illustration = () => import('@/entities/illustration/illustration.vue');
// prettier-ignore
const IllustrationUpdate = () => import('@/entities/illustration/illustration-update.vue');
// prettier-ignore
const IllustrationDetails = () => import('@/entities/illustration/illustration-details.vue');
// prettier-ignore
const Catalog = () => import('@/entities/catalog/catalog.vue');
// prettier-ignore
const CatalogUpdate = () => import('@/entities/catalog/catalog-update.vue');
// prettier-ignore
const CatalogDetails = () => import('@/entities/catalog/catalog-details.vue');
// prettier-ignore
const CatalogHierarchyProduct = () => import('@/entities/catalog/catalog-hierarchy-product.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const CatalogCategory = () => import('@/entities/catalog-category/catalog-category.vue');
// prettier-ignore
const CatalogCategoryUpdate = () => import('@/entities/catalog-category/catalog-category-update.vue');
// prettier-ignore
const CatalogCategoryDetails = () => import('@/entities/catalog-category/catalog-category-details.vue');
// prettier-ignore
const ProductChild = () => import('@/entities/product-child/product-child.vue');
// prettier-ignore
const ProductChildUpdate = () => import('@/entities/product-child/product-child-update.vue');
// prettier-ignore
const ProductChildDetails = () => import('@/entities/product-child/product-child-details.vue');
// prettier-ignore
const SalePlace = () => import('@/entities/sale-place/sale-place.vue');
// prettier-ignore
const SalePlaceUpdate = () => import('@/entities/sale-place/sale-place-update.vue');
// prettier-ignore
const SalePlaceDetails = () => import('@/entities/sale-place/sale-place-details.vue');
// prettier-ignore
const Transaction = () => import('@/entities/transaction/transaction.vue');
// prettier-ignore
const TransactionUpdate = () => import('@/entities/transaction/transaction-update.vue');
// prettier-ignore
const TransactionDetails = () => import('@/entities/transaction/transaction-details.vue');
// prettier-ignore
const CashAccount = () => import('@/entities/cash-account/cash-account.vue');
// prettier-ignore
const CashAccountUpdate = () => import('@/entities/cash-account/cash-account-update.vue');
// prettier-ignore
const CashAccountDetails = () => import('@/entities/cash-account/cash-account-details.vue');
// prettier-ignore
const Operator = () => import('@/entities/operator/operator.vue');
// prettier-ignore
const OperatorUpdate = () => import('@/entities/operator/operator-update.vue');
// prettier-ignore
const OperatorDetails = () => import('@/entities/operator/operator-details.vue');
// prettier-ignore
const OperatorRole = () => import('@/entities/operator-role/operator-role.vue');
// prettier-ignore
const OperatorRoleUpdate = () => import('@/entities/operator-role/operator-role-update.vue');
// prettier-ignore
const OperatorRoleDetails = () => import('@/entities/operator-role/operator-role-details.vue');
// prettier-ignore
const CashOrder = () => import('@/entities/cash-order/cash-order.vue');
// prettier-ignore
const CashOrderDetails = () => import('@/entities/cash-order/cash-order-details.vue');
// prettier-ignore
const CashAccountItem = () => import('@/entities/cash-account-item/cash-account-item.vue');
// prettier-ignore
const CashAccountItemUpdate = () => import('@/entities/cash-account-item/cash-account-item-update.vue');
// prettier-ignore
const CashAccountItemDetails = () => import('@/entities/cash-account-item/cash-account-item-details.vue');
// prettier-ignore
const AppActionSync = () => import('@/entities/app-action-sync/app-action-sync.vue');
// prettier-ignore
const AppActionSyncUpdate = () => import('@/entities/app-action-sync/app-action-sync-update.vue');
// prettier-ignore
const AppActionSyncDetails = () => import('@/entities/app-action-sync/app-action-sync-details.vue');
// prettier-ignore
const CashOrderSync = () => import('@/entities/cash-order-sync/cash-order-sync.vue');
// prettier-ignore
const CashOrderSyncUpdate = () => import('@/entities/cash-order-sync/cash-order-sync-update.vue');
// prettier-ignore
const CashOrderSyncDetails = () => import('@/entities/cash-order-sync/cash-order-sync-details.vue');
// prettier-ignore
const Space = () => import('@/entities/space/space.vue');
// prettier-ignore
const SpaceUpdate = () => import('@/entities/space/space-update.vue');
// prettier-ignore
const SpaceDetails = () => import('@/entities/space/space-details.vue');
const Sale = () => import('@/entities/sale/sale.vue');
const NfcCard = () => import('@/entities/nfc-card/nfc-card.vue');
const NfcCardDetails = () => import('@/entities/nfc-card/nfc-card-details.vue');
const Audit = () => import('@/entities/audit/audit.vue');
const AuditDetails = () => import('@/entities/audit/audit-details.vue');
// prettier-ignore
// prettier-ignore
const PrinterTemplate = () => import('@/entities/printer-template/printer-template.vue');
// prettier-ignore
const PrinterTemplateUpdate = () => import('@/entities/printer-template/printer-template-update.vue');
// prettier-ignore
const PrinterTemplateDetails = () => import('@/entities/printer-template/printer-template-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'cash-event',
      name: 'CashEvent',
      component: CashEvent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-event/new',
      name: 'CashEventCreate',
      component: CashEventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-event/:cashEventId/edit',
      name: 'CashEventEdit',
      component: CashEventUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-event/:cashEventId/view',
      name: 'CashEventView',
      component: CashEventDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tva',
      name: 'Tva',
      component: Tva,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tva/new',
      name: 'TvaCreate',
      component: TvaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tva/:tvaId/edit',
      name: 'TvaEdit',
      component: TvaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tva/:tvaId/view',
      name: 'TvaView',
      component: TvaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'terminal',
      name: 'Terminal',
      component: Terminal,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'terminal/new',
      name: 'TerminalCreate',
      component: TerminalUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'terminal/:terminalId/edit',
      name: 'TerminalEdit',
      component: TerminalUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'terminal/:terminalId/view',
      name: 'TerminalView',
      component: TerminalDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'illustration',
      name: 'Illustration',
      component: Illustration,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'illustration/new',
      name: 'IllustrationCreate',
      component: IllustrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'illustration/:illustrationId/edit',
      name: 'IllustrationEdit',
      component: IllustrationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'illustration/:illustrationId/view',
      name: 'IllustrationView',
      component: IllustrationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog',
      name: 'Catalog',
      component: Catalog,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog/new',
      name: 'CatalogCreate',
      component: CatalogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog/:catalogId/edit',
      name: 'CatalogEdit',
      component: CatalogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog/:catalogId/view',
      name: 'CatalogView',
      component: CatalogDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product',
      name: 'Product',
      component: Product,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog-category',
      name: 'CatalogCategory',
      component: CatalogCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog-category/new',
      name: 'CatalogCategoryCreate',
      component: CatalogCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog-category/:catalogCategoryId/edit',
      name: 'CatalogCategoryEdit',
      component: CatalogCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'catalog-category/:catalogCategoryId/view',
      name: 'CatalogCategoryView',
      component: CatalogCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-child',
      name: 'ProductChild',
      component: ProductChild,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-child/new',
      name: 'ProductChildCreate',
      component: ProductChildUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-child/:productChildId/edit',
      name: 'ProductChildEdit',
      component: ProductChildUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-child/:productChildId/view',
      name: 'ProductChildView',
      component: ProductChildDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sale-place',
      name: 'SalePlace',
      component: SalePlace,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sale-place/new',
      name: 'SalePlaceCreate',
      component: SalePlaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sale-place/:salePlaceId/edit',
      name: 'SalePlaceEdit',
      component: SalePlaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sale-place/:salePlaceId/view',
      name: 'SalePlaceView',
      component: SalePlaceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction',
      name: 'Transaction',
      component: Transaction,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction/new',
      name: 'TransactionCreate',
      component: TransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction/:transactionId/edit',
      name: 'TransactionEdit',
      component: TransactionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transaction/:transactionId/view',
      name: 'TransactionView',
      component: TransactionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account',
      name: 'CashAccount',
      component: CashAccount,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account/new',
      name: 'CashAccountCreate',
      component: CashAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account/:cashAccountId/edit',
      name: 'CashAccountEdit',
      component: CashAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account/:cashAccountId/view',
      name: 'CashAccountView',
      component: CashAccountDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator',
      name: 'Operator',
      component: Operator,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/new',
      name: 'OperatorCreate',
      component: OperatorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/:operatorId/edit',
      name: 'OperatorEdit',
      component: OperatorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator/:operatorId/view',
      name: 'OperatorView',
      component: OperatorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator-role',
      name: 'OperatorRole',
      component: OperatorRole,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator-role/new',
      name: 'OperatorRoleCreate',
      component: OperatorRoleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator-role/:operatorRoleId/edit',
      name: 'OperatorRoleEdit',
      component: OperatorRoleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'operator-role/:operatorRoleId/view',
      name: 'OperatorRoleView',
      component: OperatorRoleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order',
      name: 'CashOrder',
      component: CashOrder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order/:cashOrderId/view',
      name: 'CashOrderView',
      component: CashOrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account-item',
      name: 'CashAccountItem',
      component: CashAccountItem,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account-item/new',
      name: 'CashAccountItemCreate',
      component: CashAccountItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account-item/:cashAccountItemId/edit',
      name: 'CashAccountItemEdit',
      component: CashAccountItemUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-account-item/:cashAccountItemId/view',
      name: 'CashAccountItemView',
      component: CashAccountItemDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-action-sync',
      name: 'AppActionSync',
      component: AppActionSync,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-action-sync/new',
      name: 'AppActionSyncCreate',
      component: AppActionSyncUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-action-sync/:cashOrderSyncId/edit',
      name: 'AppActionSyncEdit',
      component: AppActionSyncUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'app-action-sync/:appActionSyncId/view',
      name: 'AppActionSyncView',
      component: AppActionSyncDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order-sync',
      name: 'CashOrderSync',
      component: CashOrderSync,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order-sync/new',
      name: 'CashOrderSyncCreate',
      component: CashOrderSyncUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order-sync/:cashOrderSyncId/edit',
      name: 'CashOrderSyncEdit',
      component: CashOrderSyncUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cash-order-sync/:cashOrderSyncId/view',
      name: 'CashOrderSyncView',
      component: CashOrderSyncDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'space',
      name: 'Space',
      component: Space,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'space/new',
      name: 'SpaceCreate',
      component: SpaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'space/:spaceId/edit',
      name: 'SpaceEdit',
      component: SpaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'space/:spaceId/view',
      name: 'SpaceView',
      component: SpaceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sale',
      name: 'Sale',
      component: Sale,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nfc-card',
      name: 'nfcCardView',
      component: NfcCard,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nfc-card/:supportNfcIdentifier/view',
      name: 'NfcCardView',
      component: NfcCardDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'audit',
      name: 'Audit',
      component: Audit,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'audit/:auditId/view',
      name: 'AuditView',
      component: AuditDetails,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'printer-template',
      name: 'PrinterTemplate',
      component: PrinterTemplate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'printer-template/new',
      name: 'PrinterTemplateCreate',
      component: PrinterTemplateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'printer-template/:printerTemplateId/edit',
      name: 'PrinterTemplateEdit',
      component: PrinterTemplateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'printer-template/:printerTemplateId/view',
      name: 'PrinterTemplateView',
      component: PrinterTemplateDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
